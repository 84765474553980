import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
// import vid4 from "../assets/videos/vid2.mp4"

const Laboratory = ({ data }) => {
  const { strapiLaboratoryPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Laboratory" />
      <VideoBanner heading={info.banner_heading} vid={info.banner_video.url} />
      <Container className="my-5">
        <Row>
          {info.lab_process.map(process => {
            const path = getImage(process.process_image[0]?.localFile)
            return (
              <Col lg={4} key={process.id} className="text-center">
                <GatsbyImage image={path} alt={process.process_heading} />
                <h3 className="my-4">{process.process_heading}</h3>
                <p className="my-1">{process.process_description}</p>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiLaboratoryPage {
      banner_heading
      banner_video {
        url
      }
      lab_process {
        id
        process_heading
        process_description
        process_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

export default Laboratory
