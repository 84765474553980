import React from "react"
import Header from "./Header"

const VideoBanner = ({ heading, para, btn, vid }) => {
  return (
    <section className="banner">
      <div className="banner-container">
        <div className="nav-container">
          <Header />
        </div>
        <div className="video-container">
          <video loop muted autoPlay playsInline>
            <source src={vid} type="video/mp4" />
          </video>
        </div>
        {/* <img src={vid} className="hero-img" alt="hero-img" /> */}
        <div className="details-video-container">
          <h1>{heading}</h1>
          <p>{para}</p>
          {btn && (
            <a href="/about" className="btn btn-yellow">
              View More
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default VideoBanner
